import { getConfig } from './TokenConfig';

export const identityServerUrl = getConfig().identityServer;
export const endSessionEndpoint = `${identityServerUrl}/connect/endsession`;

async function generateCodeVerifier() {
  const array = new Uint8Array(32);
  window.crypto.getRandomValues(array);
  return base64UrlEncode(array);
}

async function generateCodeChallenge(verifier) {
  const digest = await window.crypto.subtle.digest('SHA-256',
    new TextEncoder().encode(verifier));
  return base64UrlEncode(new Uint8Array(digest));
}

function base64UrlEncode(buffer) {
  return btoa(String.fromCharCode(...new Uint8Array(buffer)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}

export const isTokenValid = (token) => {
  try {
    if (typeof token === 'string') {
      console.error('isTokenValid called with raw token:', token);
      console.trace();
      return false;
    }
    
    const currentTime = Math.floor(Date.now() / 1000);
    let isValid = true;

    if (token.exp <= currentTime) {
      console.error('Token validation failed: Token has expired');
      isValid = false;
    }
    if (token.iss !== identityServerUrl) {
      console.error('Token validation failed: Invalid issuer');
      isValid = false;
    }
    if (!token.aud.includes("clientportal") && !token.aud.includes("censusupload")) {
      console.error('Token validation failed: Invalid audience');
      isValid = false;
    }

    return isValid;
  } catch (error) {
    console.error('Error validating token:', error);
    return false;
  }
};

export const generateNonce = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

export const redirectToIdentityServer = async () => {
  const config = getConfig();
  const isDevEnvironment = config.hostname.includes('localhost') || 
                          config.hostname.includes('censusupload-dev.guidantfinancial.com');
 
  let nonce = sessionStorage.getItem('auth_nonce');
  if (!nonce) {
    nonce = generateNonce();
    sessionStorage.setItem('auth_nonce', nonce);
  }

  const loginUrl = `${config.identityServer}/connect/authorize`
  + `?client_id=${config.clientId}`
  + `&redirect_uri=${encodeURIComponent(config.redirectUri)}`
  + `&response_type=code%20id_token`
  + `&scope=openid%20profile%20email%20role%20register`
  + `&response_mode=fragment`
  + `&nonce=${nonce}`;
  
  window.location.href = loginUrl;
 
  // if (isDevEnvironment) {
  //   const codeVerifier = await generateCodeVerifier();
  //   const codeChallenge = await generateCodeChallenge(codeVerifier);
  //   sessionStorage.setItem('code_verifier', codeVerifier);
  //   const loginUrl = `${config.identityServer}/connect/authorize`
  //     + `?client_id=${config.clientId}`
  //     + `&redirect_uri=${encodeURIComponent(config.redirectUri)}`
  //     + `&response_type=code`
  //     + `&scope=openid%20profile`
  //     + `&code_challenge=${encodeURIComponent(codeChallenge)}`
  //     + `&code_challenge_method=S256`;
      
  //   window.location.href = loginUrl;
  // } else {
  //   const loginUrl = `${config.identityServer}/connect/authorize`
  //     + `?client_id=${config.clientId}`
  //     + `&redirect_uri=${encodeURIComponent(config.redirectUri)}`
  //     + `&response_type=code%20id_token`
  //     + `&scope=openid%20profile%20email%20role%20register`
  //     + `&response_mode=fragment`
  //     + `&nonce=${nonce}`;
      
  //   window.location.href = loginUrl;
  // }
 };

export const logout = (idToken) => {
  localStorage.removeItem('id_token');
  localStorage.removeItem('decoded_token');
  sessionStorage.removeItem('code_verifier');
  sessionStorage.removeItem('auth_nonce');
  
  const config = getConfig();
  const logoutUrl = `${endSessionEndpoint}?id_token_hint=${encodeURIComponent(idToken)}&post_logout_redirect_uri=${encodeURIComponent(config.logoutRedirectUri)}`;

  window.location.href = logoutUrl;
};