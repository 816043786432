// OnboardingModal.jsx
import React, { useState } from 'react';
import './OnboardingModal.css';

import step1 from '../assets/step1.svg';
import step2 from '../assets/step2.svg';
import step3 from '../assets/step3.svg';
import step4 from '../assets/step4.svg';

import continueArrow from '../assets/continue-arrow.svg'

const OnboardingModal = ({ onClose, censusYearData }) => {
 const [currentSlide, setCurrentSlide] = useState(0);
 const [imageLoading, setImageLoading] = useState(true);

 const formatDate = (dateString) => {
   const [year, month, day] = dateString.split('-').map(Number);
   const date = new Date(year, month - 1, day, 12);
   return date.toLocaleDateString('en-US', {
     year: 'numeric',
     month: 'short',
     day: '2-digit'
   });
 };

 const slides = [
   {
     title: (
       <>
         <div style={{ fontWeight: '700', marginBottom: '16px', color: "#000000" }}>
           Welcome to the new Census experience!
         </div>
         If you completed your census last year, we have pre-populated the table with the active employees from the beginning of the plan year:
         <div className="date-range">
           {formatDate(censusYearData.current_year_start_date)} - {formatDate(censusYearData.current_year_end_date)}
         </div>
       </>
     ),
     image: step1
   },
   {
     title: "Upload your payroll data here or add it manually in the table. Don't worry—uploading your data won't remove any existing employees in the census table, it will only add to it.",
     image: step2
   },
   {
     title: (
       <>
         Upload your Guidant CSV template here.
         <div className="new-feature">
           <span className="new-label">NEW:</span> You can upload supported Paychex and ADP reports directly.
         </div>
       </>
     ),
     image: step3
   },
   {
     title: `Once the documents are uploaded, click "Submit and Generate" to review and finalize your census data.`,
     image: step4
   }
 ];

 const handleNext = () => {
   if (currentSlide < slides.length - 1) {
     setImageLoading(true);
     setCurrentSlide(prev => prev + 1);
   }
 };

 const handleBack = () => {
   if (currentSlide > 0) {
     setImageLoading(true);
     setCurrentSlide(prev => prev - 1);
   }
 };

 React.useEffect(() => {
   // Preload all step images
   [step1, step2, step3, step4].forEach(imageSrc => {
     const img = new Image();
     img.src = imageSrc;
   });
   
   // Also preload the arrow if needed
   const arrowImg = new Image();
   arrowImg.src = continueArrow;
 }, []);

 const handleFinish = () => {
   onClose();
 };

 return (
   <div className="onboarding-modal-overlay">
     <div className="onboarding-modal-content">
       <div className="onboarding-modal-header">
         <div className="onboarding-modal-step-indicator">Step {currentSlide + 1}</div>
         <button className="onboarding-modal-close" onClick={onClose}>×</button>
       </div>

       <div className="onboarding-modal-title">{slides[currentSlide].title}</div>

       <div className="onboarding-modal-image">
         {imageLoading && (
           <div className="loading-spinner">
             <div className="spinner"></div>
           </div>
         )}
         <img 
           src={slides[currentSlide].image} 
           alt={`Step ${currentSlide + 1}`}
           style={{
             height: currentSlide === 1 ? '75%' : '90%',
             display: imageLoading ? 'none' : 'block'
           }}
           onLoad={() => setImageLoading(false)}
         />
       </div>

       <div className="onboarding-modal-footer">
         <div className="onboarding-modal-nav-wrapper">
           <div className="onboarding-modal-nav-space">
             <button 
               className={`onboarding-modal-nav-button onboarding-modal-back ${currentSlide === 0 ? 'disabled' : ''}`}
               onClick={handleBack}
               disabled={currentSlide === 0}
             >
               BACK
             </button>
           </div>

           <div className="onboarding-modal-dots">
             {slides.map((_, index) => (
               <div 
                 key={index}
                 className={`onboarding-modal-dot ${index <= currentSlide ? 'active' : ''}`}
               />
             ))}
           </div>

           <div className="onboarding-modal-nav-space">
             {currentSlide < slides.length - 1 ? (
               <button className="onboarding-modal-nav-button onboarding-modal-continue" onClick={handleNext}>
                 CONTINUE
                 <img src={continueArrow} alt="" className="continue-arrow" />
               </button>
             ) : (
               <button className="onboarding-modal-nav-button onboarding-modal-finish" onClick={handleFinish}>
                 FINISH
               </button>
             )}
           </div>
         </div>
       </div>
     </div>
   </div>
 );
};

export default OnboardingModal;