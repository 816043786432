import React, { useState, useRef, useEffect } from 'react';
import './TopNavBar.css';
import logo from '../../assets/guidant-logo.svg';
import signoutIcon from '../../assets/signout-icon.png';
import helpIcon from '../../assets/nav-help-icon.svg';
import HelpModal from '../Layout/HelpModal';

function TopNavBar({logout, showOnboardingGuide, showHelpModal, setShowHelpModal}) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [helpModalVisible, setHelpModalVisible] = useState(false);
  const dropdownRef = useRef(null);
  const helpRef = useRef(null);

  const handleSignOut = () => {
    logout();
  };

  // Close dropdowns when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
          !event.target.closest('.account-link')) {
        setDropdownVisible(false);
      }
      if (helpRef.current && !helpRef.current.contains(event.target) &&
          !event.target.closest('.help-icon')) {
        setHelpModalVisible(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, helpRef]);

  return (
    <header className="top-nav-bar">
      <div className="logo">
        <img src={logo} alt="Guidant Logo" />
      </div>
      <div className="nav-links">
      <div className="help-container" ref={helpRef}>
        <button 
          className="help-icon"
          onClick={() => setShowHelpModal(!showHelpModal)}
        >
          <img src={helpIcon} alt="Help" />
        </button>
        {showHelpModal && 
          <HelpModal 
            onClose={() => setShowHelpModal(false)} 
            showOnboardingGuide={showOnboardingGuide}
          />
        }
      </div>
        <span>425-289-3200</span>
        <a 
          href="#!" 
          onClick={() => setDropdownVisible(!dropdownVisible)} 
          className="account-link"
        >
          My Account
        </a>
        {dropdownVisible && (
          <div ref={dropdownRef} className="dropdown-menu">
            <div className="signout-button" onClick={handleSignOut}>
              <img src={signoutIcon} alt="Sign Out Icon" className="signout-icon" />
              <span>Sign Out</span>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

export default TopNavBar;