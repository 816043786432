import React from 'react';
import './AlertTag.css';
import warningIcon from '../../assets/warning-alert-icon.png';
import errorIcon from '../../assets/error-alert-icon.png';
import invalidIcon from '../../assets/invalid-alert-icon.png';

import warningActiveIcon from '../../assets/warning-alert-active-icon.png';
import errorActiveIcon from '../../assets/error-alert-active-icon.png';
import invalidActiveIcon from '../../assets/invalid-alert-active-icon.png';

import whiteXIcon from '../../assets/white-x-icon.png';
import blackXIcon from '../../assets/black-x-icon.png';


const iconMap = {
  "Warnings": {
    inactive: warningIcon,
    active: warningActiveIcon,
    closeIcon: blackXIcon
  },
  "Errors": {
    inactive: errorIcon,
    active: errorActiveIcon,
    closeIcon: whiteXIcon
  },
  "Required Fields": {
    inactive: invalidIcon,
    active: invalidActiveIcon,
    closeIcon: whiteXIcon
  }
};

function AlertTag({ label, count, onClick, active, onClose }) {
  const icon = active ? iconMap[label].active : iconMap[label].inactive;
  const closeIcon = active ? iconMap[label].closeIcon : null;
  const iconClass = `${label.replace(' ', '-').toLowerCase()}-icon ${active ? 'active' : 'inactive'}`;

  return (
    <div className={`alert-tag ${label.replace(' ', '-')} ${active ? 'active' : ''}`} onClick={onClick}>
      <img src={icon} alt={`${label} Icon`} className={`alert-icon ${iconClass}`} />
      <span className="alert-label">{label}</span>
      <span className="alert-count">({count})</span>
      {active && (
        <img
          src={closeIcon}
          alt="Close Icon"
          className="close-icon"
          onClick={(e) => {
            if (onClose) onClose();
          }}
        />
      )}
    </div>
  );
}

export default AlertTag;