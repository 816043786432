import React, { useState, useEffect } from 'react';
import MainContentHeader from '../MainContentHeader';
import ConflictModal from './ConflictModal';
import BulkConflictModal from './BulkConflictModal';
import HoursWorkedModal from './HoursWorkedModal';
import ResultsTableSection from './ResultsTableSection';
import './ResultsContent.css';
import saveIcon from './../../assets/save-icon.png';
import useResultsData from './useResultsData';
import useProcessingStatus from './useProcessingStatus';
import ProcessingModal from './ProcessingModal';
import Sidebar from './Sidebar';
import sideBarToggleButton from '../../assets/sidebar-toggle-button.png';
import whiteTrashIcon from '../../assets/white-trash-icon.png';
import editPencilButton from '../../assets/edit-pencil-icon.png';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import ValidationErrorModal from './ValidationErrorModal';
import { archiveProject, saveData, submitFinalData } from '../../api';
import addManuallyIcon from './../../assets/add-manually-icon.png';
import uploadFileIcon from './../../assets/upload-file-icon.png';

const filterData = (data, searchTerm, alertFilters, censusYearData) => {
  if (!data || !data.Row) return { Row: {} };

  const lowercasedFilter = searchTerm ? searchTerm.toLowerCase() : '';
  const searchParts = lowercasedFilter.split(" "); // Split search term into parts

  const filteredRows = Object.entries(data.Row).filter(([key, employee]) => {
    const terminationDate = employee.DateOfTermination?.FinalValue;
    const hireDate = employee.DateOfHire?.FinalValue;

    // Check if any field has an 'Error' status
    const hasErrorStatus = Object.values(employee).some(field => 
      ['Error', 'Invalid'].includes(field?.FinalValue?.Status)
    );
    
    // Check for GrossCompensation and HoursWorked values greater than 0
    const hasValidGrossCompensation = parseFloat(employee.GrossCompensation?.FinalValue?.Value) > 0;
    const hasValidHoursWorked = parseFloat(employee.HoursWorked?.FinalValue?.Value) > 0;

    if (terminationDate && terminationDate.Source === "PriorYear") {
      if (
        hireDate?.Value?.toLowerCase() === "conflict" ||
        hireDate?.Value?.toLowerCase() === "rehire" ||
        hasErrorStatus ||
        hasValidGrossCompensation ||
        hasValidHoursWorked ||
        new Date(terminationDate.Value) >= new Date(censusYearData.current_year_start_date)
      ) {
        // Keep this employee visible
      } else {
        return false;
      }
    }

    // Helper function to match search term against both FinalValue and Values array
    const matchField = (field) => {
      const finalValueMatch = field?.FinalValue?.Value?.toString().toLowerCase().includes(lowercasedFilter);
      const valuesArrayMatch = field?.Values?.some(valueObj =>
        valueObj.Value?.toString().toLowerCase().includes(lowercasedFilter)
      );
      return finalValueMatch || valuesArrayMatch;
    };

    let matchesSearchTerm = true;
    if (searchTerm) {
      matchesSearchTerm = false;

      // Check if search term contains multiple words (e.g., full name)
      if (searchParts.length > 1) {
        // Assume first part is first name and last part is last name
        const firstNameMatch = employee.FirstName && (
          employee.FirstName?.FinalValue?.Value?.toLowerCase().includes(searchParts[0]) ||
          employee.FirstName?.Values?.some(valueObj => valueObj.Value?.toLowerCase().includes(searchParts[0]))
        );

        const lastNameMatch = employee.LastName && (
          employee.LastName?.FinalValue?.Value?.toLowerCase().includes(searchParts[1]) ||
          employee.LastName?.Values?.some(valueObj => valueObj.Value?.toLowerCase().includes(searchParts[1]))
        );

        // Both first name and last name should match
        if (firstNameMatch && lastNameMatch) {
          matchesSearchTerm = true;
        }
      }

      // If not a full name, fallback to checking individual fields
      if (!matchesSearchTerm) {
        matchesSearchTerm = Object.entries(employee).some(([fieldKey, field]) => {
          if (fieldKey === 'FirstName' || fieldKey === 'LastName') {
            // Check both FinalValue and Values array for FirstName and LastName
            return matchField(field);
          } else {
            // Only check FinalValue for other fields
            return field?.FinalValue?.Value?.toString().toLowerCase().includes(lowercasedFilter);
          }
        });
      }
    }

    const matchesAlertFilter = !alertFilters || alertFilters.length === 0 || Object.values(employee).some(field => {
      return alertFilters.includes(field?.FinalValue?.Status);
    });

    return matchesSearchTerm && matchesAlertFilter;
  });
  return { Row: Object.fromEntries(filteredRows) };
};




function ResultsContent({ currentProject, setCurrentView, customerNumber, currentView, setIsLoading, iFinanceId, priorYearIFinanceId, censusYearData }) {
  const {
    data,
    oldData,
    reportStartDates,
    reportEndDates,
    initialEditableRowData,
    setData,
    setOldData,
    sources,
    fetchResults,
    currentYearAlerts,
    previousYearAlerts
  } = useResultsData(currentProject, false, currentView, setIsLoading, iFinanceId, censusYearData, customerNumber);

  const {
    processingStatus,
    showProcessingModal,
    setShowProcessingModal,
    checkProcessingStatusAndShowModal
  } = useProcessingStatus(currentProject, fetchResults);

  const [activeTab, setActiveTab] = useState('viewAll');
  const [currentPage, setCurrentPage] = useState(1);
  const [oldCurrentPage, setOldCurrentPage] = useState(1);
  const [showCurrentYearTable, setShowCurrentYearTable] = useState(true);
  const [showPreviousYearTable, setShowPreviousYearTable] = useState(true);
  const [isAddingNewRow, setIsAddingNewRow] = useState(false);
  const [isAddingOldNewRow, setIsAddingOldNewRow] = useState(false);
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [editableOldRowIndex, setEditableOldRowIndex] = useState(null);
  const [editableRowData, setEditableRowData] = useState(initialEditableRowData);
  const [editableOldRowData, setEditableOldRowData] = useState(initialEditableRowData);
  const [currentYearSearchTerm, setCurrentYearSearchTerm] = useState('');
  const [previousYearSearchTerm, setPreviousYearSearchTerm] = useState('');
  const [currentYearAlertFilters, setCurrentYearAlertFilters] = useState([]);
  const [previousYearAlertFilters, setPreviousYearAlertFilters] = useState([]);  
  const [showConflictModal, setShowConflictModal] = useState(false);
  const [showHoursModal, setShowHoursModal] = useState(false);
  const [selectedEmployeeFullName, setSelectedEmployeeFullName] = useState('');
  const [conflictValues, setConflictValues] = useState([]);
  const [isOldDataSaving, setIsOldDataSaving] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idDeletingOldData, setIsDeletingOldData] = useState(null);
  const [employeeKeyToDelete, setEmployeeKeyToDelete] = useState('');
  const [deleteMessage, setDeleteMessage] = useState('');
  const [isDeletingAll, setIsDeletingAll] = useState(false);

  const [validationErrors, setValidationErrors] = useState([]);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [saveRequest, setSaveRequest] = useState(false);

  const [hoursFinalValue, setHoursFinalValue] = useState('');
  const [hoursWorkedNoteFinalValue, setHoursWorkedNoteFinalValue] = useState('');
  const [hoursIsOwner, setHoursIsOwner] = useState(false);
  const [hoursGrossCompensation, setHoursGrossCompensation] = useState('');

  const [showBulkConflictModal, setShowBulkConflictModal] = useState(false);
  const [bulkConflictSources, setBulkConflictSources] = useState([]);
  const [bulkConflictField, setBulkConflictField] = useState('');
  const [isOldDataBulkResolve, setIsOldDataBulkResolve] = useState(false);

  const [isRehireConflict, setIsRehireConflict] = useState(false);

  const [originalRowData, setOriginalRowData] = useState({});

  useEffect(() => {
    // Update current year filters based on alerts
    setCurrentYearAlertFilters(prevFilters => {
      let updatedFilters = [...prevFilters];
      
      if (currentYearAlerts.errors === 0) {
        updatedFilters = updatedFilters.filter(f => f !== 'Error');
      }
      if (currentYearAlerts.warnings === 0) {
        updatedFilters = updatedFilters.filter(f => f !== 'Warning');
      }
      if (currentYearAlerts.invalids === 0) {
        updatedFilters = updatedFilters.filter(f => f !== 'Invalid');
      }
      
      return updatedFilters;
    });
  
    // Update previous year filters based on alerts
    setPreviousYearAlertFilters(prevFilters => {
      let updatedFilters = [...prevFilters];
      
      if (previousYearAlerts.errors === 0) {
        updatedFilters = updatedFilters.filter(f => f !== 'Error');
      }
      if (previousYearAlerts.warnings === 0) {
        updatedFilters = updatedFilters.filter(f => f !== 'Warning');
      }
      if (previousYearAlerts.invalids === 0) {
        updatedFilters = updatedFilters.filter(f => f !== 'Invalid');
      }
      
      return updatedFilters;
    });
  }, [currentYearAlerts, previousYearAlerts]);

  useEffect(() => {
    if (saveRequest) {
      SaveData();
      setSaveRequest(false);
    }
  }, [saveRequest, data, oldData]);

  const HandleSetCurrentYearSearchTerm = (term) => 
  {
    if(currentPage != 1){
      setCurrentPage(1);
    }
    setCurrentYearSearchTerm(term);
  }

  const HandleSetPreviousYearSearchTerm = (term)  => 
  {
    if(oldCurrentPage != 1){
      setOldCurrentPage(1);
    }

    setPreviousYearSearchTerm(term);
  }

  
  const SaveData = async () => {
    setIsLoading(true);
    try {
      const projectId = currentProject;
      
      const reportPeriodStartDate = (reportStartDates && reportStartDates.length > 0)
        ? reportStartDates.map(date => ({
            Value: date.Value,
            Source: date.Source
          }))
        : [];
    
      const reportPeriodEndDate = (reportEndDates && reportEndDates.length > 0)
        ? reportEndDates.map(date => ({
            Value: date.Value,
            Source: date.Source
          }))
        : [];
    
      // Merge the latest data and oldData
      const mergedRowData = { ...data.Row, ...oldData.Row };
      
      const reconstructedData = {
        ReportPeriodStartDate: reportPeriodStartDate,
        ReportPeriodEndDate: reportPeriodEndDate,
        Row: mergedRowData
      };
      
      await saveData(projectId, reconstructedData);
      await fetchResults();
    } catch (error) {
      console.error('Failed to save data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const SubmitFinalData = async () => {
    setIsLoading(true);
    try {
      // Prepare and submit data
      const reportPeriodStartDate = (reportStartDates && reportStartDates.length > 0)
        ? reportStartDates.map(date => ({
            Value: date.Value,
            Source: date.Source
          }))
        : [];
  
      const reportPeriodEndDate = (reportEndDates && reportEndDates.length > 0)
        ? reportEndDates.map(date => ({
            Value: date.Value,
            Source: date.Source
          }))
        : [];
  
      const rowData = {
        ...((data && data.Row) || {}),
        ...((oldData && oldData.Row) || {})
      };
  
      // Check for 'Error' or 'Missing' statuses in FinalValue fields
      let hasErrorsOrMissing = false;
      Object.values(rowData).forEach(employee => {
        Object.values(employee).forEach(field => {
          if (field?.FinalValue?.Status === 'Error' || field?.FinalValue?.Status === 'Invalid') {
            hasErrorsOrMissing = true;
          }
        });
      });
  
      if (hasErrorsOrMissing) {
        alert('Please fix all errors and missing data before submitting.');
        setIsLoading(false);
        return;
      }
  
      const reconstructedData = {
        ReportPeriodStartDate: reportPeriodStartDate,
        ReportPeriodEndDate: reportPeriodEndDate,
        Row: rowData
      };
      
      const response = await submitFinalData(customerNumber, censusYearData.current_year, censusYearData.prior_year, censusYearData.current_year_end_date, censusYearData.current_year_start_date, censusYearData.prior_year_start_date, censusYearData.prior_year_end_date, iFinanceId, priorYearIFinanceId, reconstructedData, currentProject);
      if (response.success) {
       let baseUrl;
      
       // Check the current window location to determine the environment
       const currentHostname = window.location.hostname;
      
       if (
         currentHostname === 'localhost' || 
         currentHostname === '127.0.0.1' ||
         currentHostname === 'censusupload-dev.guidantfinancial.com' ||
         currentHostname === 'guidant-census-data.azurewebsites.net'
       ) {
         baseUrl = 'https://census-dev.guidantfinancial.com/EmployeeStatus';
       } else if (
         currentHostname === 'censusupload-stg.guidantfinancial.com' ||
         currentHostname === 'guidant-census-data-stg.azurewebsites.net'
       ) {
         baseUrl = 'https://census-stg.guidantfinancial.com/EmployeeStatus';
       } else if (
         currentHostname === 'censusupload.guidantfinancial.com' ||
         currentHostname === 'guidant-census-data-prod.azurewebsites.net'
       ) {
         baseUrl = 'https://census.guidantfinancial.com/EmployeeStatus';
       } else {
         throw new Error('Unknown environment: Unable to determine census URL');
       }
      
       // Redirect to the appropriate URL with iFinanceId
       window.location.href = `${baseUrl}/${iFinanceId}`;
      } else {
       console.warn('Validation errors:', response.errors);
       setValidationErrors(response.errors);
       setShowValidationModal(true);
      }
      
      } catch (error) {
       console.error('Failed to submit final data:', error);
      } finally {
       setIsLoading(false);
      }
      };
  
  
  
      const handleDeleteRowClick = async (employeeKey, isOldData) => {
        if (processingStatus.isProcessing) {
            if (checkProcessingStatusAndShowModal()) {
                return;
            }
        }
    
        const employeeData = isOldData ? oldData.Row[employeeKey] : data.Row[employeeKey];
    
        // Check if they're a prior year employee
        if (employeeData?.PriorYearEmployee?.FinalValue?.Value === "true") {
            // Only block deletion if their date of hire source is prioryear
            const hireDateSource = employeeData?.DateOfHire?.FinalValue?.Source?.toLowerCase();
            if (hireDateSource === "prioryear") {
                alert("You cannot delete a prior year employee.");
                return;
            }
        }
    
        // Rest of the delete logic...
        const isDuplicate = employeeKey.endsWith('_duplicate');
        const baseKey = isDuplicate ? employeeKey.replace('_duplicate', '') : employeeKey;
        const duplicateKey = isDuplicate ? employeeKey : `${employeeKey}_duplicate`;
    
        const hasMatchingEntries = (
            data.Row[baseKey] && 
            oldData.Row[duplicateKey]
        );
    
        setIsDeletingOldData(isOldData);
        setEmployeeKeyToDelete(employeeKey);
        setDeleteMessage(
            hasMatchingEntries 
                ? "Are you sure you want to delete this user? This will delete the employee from both tables."
                : "Are you sure you want to delete this user?"
        );
        setIsDeletingAll(false);
        setShowDeleteModal(true);
    };


  const handleDeleteProjectClick = async () => {
    setIsDeletingOldData(null);
    setEmployeeKeyToDelete(null);
    setDeleteMessage("Are you sure you want to delete everything submitted in the new year census?");
    setIsDeletingAll(true);
    setShowDeleteModal(true);
  }

  const handleConfirmDeleteProject = async () => {
     
    try{
      setIsLoading(true);
      const success = await archiveProject(currentProject);
  
      if (success) {
        setData([]);
        setOldData([]);
        window.location.reload();
      } else {
        alert('Failed to archive the project.');
      }
    
      setShowDeleteModal(false);

    }catch (error){
      console.log(error);
    }finally{
      setIsLoading(false);
    }

    
  }
  

  const handleConfirmDeleteRow = () => {
    const isDuplicate = employeeKeyToDelete.endsWith('_duplicate');
    
    const baseKey = isDuplicate 
      ? employeeKeyToDelete.replace('_duplicate', '')
      : employeeKeyToDelete;
  
    setOldData(prevOldData => {
      const newOldData = { ...prevOldData };
      delete newOldData.Row[employeeKeyToDelete];
      return newOldData;
    });
  
    setData(prevData => {
      const newData = { ...prevData };
      delete newData.Row[baseKey];
      return newData;
    });
  
    setShowDeleteModal(false);
    setSaveRequest(true);
  };
  
  
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const toggleSidebar = () => setIsSidebarVisible(!isSidebarVisible);

  const handlePageChange = (pageNumber, isOldData) => isOldData ? setOldCurrentPage(pageNumber) : setCurrentPage(pageNumber);  
  
  const handleResolveClick = async (values, employeeKey, field, isOldData) => {
    if (processingStatus.isProcessing) {
        if (checkProcessingStatusAndShowModal()) {
            return;
        }
    }

    const dataSource = isOldData ? oldData.Row : data.Row;
    const employeeData = dataSource[employeeKey];  // Get employee data once

    let actualField = field;
    let conflicts = [];

    // Handle rehire case
    if (field === 'rehire') {
        setIsRehireConflict(true);
        actualField = 'DateOfHire';
    } else {
        setIsRehireConflict(false);
    }

    // Handling FullName conflicts
    if (actualField === 'FullName') {
        const firstNameValues = dataSource[employeeKey].FirstName.Values;
        const lastNameValues = dataSource[employeeKey].LastName.Values;

        if (firstNameValues.length > 0 && lastNameValues.length > 0) {
            for (let i = 0; i < firstNameValues.length; i++) {
                for (let j = 0; j < lastNameValues.length; j++) {
                    conflicts.push({
                        firstName: firstNameValues[i],
                        lastName: lastNameValues[j],
                        employeeKey,
                        field: actualField,
                        isOldData
                    });
                }
            }
        } else if (firstNameValues.length > 0) {
            conflicts = firstNameValues.map(firstName => ({
                firstName,
                lastName: { Value: '', Source: '' },
                employeeKey,
                field: actualField,
                isOldData
            }));
        } else if (lastNameValues.length > 0) {
            conflicts = lastNameValues.map(lastName => ({
                firstName: { Value: '', Source: '' },
                lastName,
                employeeKey,
                field: actualField,
                isOldData
            }));
        }
    } else {
        // For all other fields
        conflicts = values.map(value => ({
            ...value,
            employeeKey,
            field: actualField,
            isOldData
        }));
    }

    // Create the new structure
    const conflictData = {
        employeeData,     // Single copy of the full employee data
        conflicts        // Array of just the conflicts
    };

    setConflictValues(conflictData);
    setShowConflictModal(true);
};
  

  const handleBulkResolveClick = (field, isOldData) => {
    const dataToCheck = isOldData ? oldData : data;
    const conflictingRows = [];

  
    // Collect rows with conflicts in the specified field
    Object.entries(dataToCheck.Row).forEach(([key, row]) => {
      if (row[field]?.FinalValue?.Value === "conflict") {
        conflictingRows.push(row);
      }
    });
  
    // Aggregate unique sources and their example values
    const sourceValuesMap = new Map();
  
    conflictingRows.forEach(row => {
      row[field].Values.forEach(valueObj => {
        const source = valueObj.Source;
        const value = valueObj.Value;
  
        // If the source isn't already in the map, add it with the example value
        if (!sourceValuesMap.has(source)) {
          sourceValuesMap.set(source, value);
        }
        // If you prefer to overwrite with the latest value found, remove the if condition
        // sourceValuesMap.set(source, value);
      });
    });
  
    // Convert the map to an array of { Source, Value } objects
    const bulkConflictSourcesArray = Array.from(sourceValuesMap.entries()).map(
      ([source, value]) => ({ Source: source, Value: value })
    );
  
    // Open modal with aggregated sources and example values
    setBulkConflictSources(bulkConflictSourcesArray);
    setBulkConflictField(field);
    setIsOldDataBulkResolve(isOldData);
    setShowBulkConflictModal(true);
  };
  

  const handleBulkConflictChange = (selectedSource) => {
    const dataToUpdate = isOldDataBulkResolve ? { ...oldData } : { ...data };
  
    Object.entries(dataToUpdate.Row).forEach(([key, row]) => {
      if (row[bulkConflictField]?.FinalValue?.Value === 'conflict') {
        const fieldValues = row[bulkConflictField].Values;
  
        // Count occurrences of the selectedSource in the Values array
        const selectedSourceValues = fieldValues.filter(value => value.Source === selectedSource);
  
        // Proceed only if selectedSource is present exactly once
        if (selectedSourceValues.length === 1) {
          const selectedValue = selectedSourceValues[0];
          row[bulkConflictField].FinalValue = { 
            ...selectedValue, 
            Status: 'Valid', 
            Message: 'Conflict resolved.' 
          };
        }
        // Else, skip resolving this conflict
      }
    });
  
    if (isOldDataBulkResolve) {
      setOldData(dataToUpdate);
    } else {
      setData(dataToUpdate);
    }
    setSaveRequest(true);
  };
  
  const handleZeroHourClick = async (employeeKey, isOldData, hoursFinalValue, grossCompensation, hoursWorkedNoteFinalValue, isOwner) => {
    if (processingStatus.isProcessing) {
      if (checkProcessingStatusAndShowModal()) {
        return;
      }
    }
    setHoursGrossCompensation(grossCompensation);
    setSelectedEmployeeFullName(employeeKey);
    setShowHoursModal(true);
    setIsOldDataSaving(isOldData);
  
    // Store the values in state to pass to the modal
    setHoursFinalValue(hoursFinalValue);
    setHoursWorkedNoteFinalValue(hoursWorkedNoteFinalValue);
    setHoursIsOwner(isOwner);
  };
  

  const handleAddRow = async (isOldData) => {
    if (processingStatus.isProcessing) {
      if (checkProcessingStatusAndShowModal()) {
        return;
      }
    }
  
    // Close any open edit rows
    setEditableRowIndex(null);
    setEditableOldRowIndex(null);
  
    if (isOldData) {
      setIsAddingOldNewRow(true);
      setIsAddingNewRow(false); // Ensure current year add row is closed
      setEditableOldRowData(initialEditableRowData);
      if (!oldData || Object.keys(oldData).length === 0) {
        setOldData([]);
      }
    } else {
      setIsAddingNewRow(true);
      setIsAddingOldNewRow(false); // Ensure previous year add row is closed
      setEditableRowData(initialEditableRowData);
      if (!data || Object.keys(data).length === 0) {
        setData([]);
      }
    }
  };

  const handleEditRow = async (key, isOldData) => {
    if (processingStatus.isProcessing) {
      if (checkProcessingStatusAndShowModal()) {
        return;
      }
    }
  
    // Close any open add rows
    setIsAddingNewRow(false);
    setIsAddingOldNewRow(false);
  
    const dataToEdit = isOldData ? oldData : data;
    const editableData = isOldData ? setEditableOldRowData : setEditableRowData;
    const setEditableIndex = isOldData ? setEditableOldRowIndex : setEditableRowIndex;
  
    // Close the other edit row if open
    if (isOldData) {
      setEditableRowIndex(null);
    } else {
      setEditableOldRowIndex(null);
    }
  
    setEditableIndex(key);
    const employee = dataToEdit.Row[key];
    if (!employee) {
      console.error('Employee data is undefined');
      return;
    }
    setOriginalRowData(employee);
    editableData({
      originalKey: key,
      Id: key,
      FullName: `${employee.FirstName?.FinalValue?.Value || ''} ${employee.MiddleName?.FinalValue?.Value || ''} ${employee.LastName?.FinalValue?.Value || ''}`.trim(),
      FirstName: employee.FirstName?.FinalValue?.Value || '',
      LastName: employee.LastName?.FinalValue?.Value || '',
      MiddleName: employee.MiddleName?.FinalValue?.Value || '',
      SSN: employee.SSN?.FinalValue?.Value || '',
      DateOfBirth: employee.DateOfBirth?.FinalValue?.Value || '',
      DateOfHire: employee.DateOfHire?.FinalValue?.Value || '',
      DateOfReHire: employee.DateOfReHire?.FinalValue?.Value || '',
      DateOfTermination: employee.DateOfTermination?.FinalValue?.Value || '',
      Status: employee.Status?.FinalValue?.Value || '',
      GrossCompensation: employee.GrossCompensation?.FinalValue?.Value || '',
      HoursWorked: employee.HoursWorked?.FinalValue?.Value || '',
      HoursWorkedNote: employee.HoursWorkedNote?.FinalValue?.Value || '',
      DeferALS: employee.DeferALS?.FinalValue?.Value || '',
      ParticipantRoth: employee.ParticipantRoth?.FinalValue?.Value || '',
      CompanyMatch: employee.CompanyMatch?.FinalValue?.Value || '',
      CompanyProfitSharing: employee.CompanyProfitSharing?.FinalValue?.Value || '',
      AnnualLoanPayment: employee.AnnualLoanPayment?.FinalValue?.Value || '',
      RollOver: employee.RollOver?.FinalValue?.Value || '',
      TypeOfStatusChange: employee.TypeOfStatusChange?.FinalValue?.Value || 'Active',
      EndDate: employee.DateOfStatusChange?.FinalValue?.Value || '',
      PriorYearEmployee: employee.PriorYearEmployee?.FinalValue?.Value || ''
    });
  };

  async function generateOriginalKey(SSN, firstName, lastName, dateOfBirth, customerNumber) {
    // Ensure firstName, lastName, and customerNumber are always trimmed and not null
    firstName = (firstName || '').trim();
    lastName = (lastName || '').trim();
    customerNumber = String(customerNumber || '').trim();
  
    // Handle SSN and dateOfBirth to avoid invalid values
    let standardizedSSN;
    if (SSN && SSN.trim()) {
      standardizedSSN = SSN.replace(/-/g, '');
    } else {
      const dobString = dateOfBirth ? new Date(dateOfBirth).toISOString().split('T')[0] : '';
      standardizedSSN = `${firstName}${lastName}${dobString}`;
    }
  
    const inputString = `${customerNumber}${standardizedSSN}`;
    const encoder = new TextEncoder();
    const data = encoder.encode(inputString);
  
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
  
    return hashHex;
  }
  

  function splitFullName(fullName) {
    const nameParts = fullName.trim().split(/\s+/);
    let firstName, middleName, lastName;
  
    if (nameParts.length === 2) {
      [firstName, lastName] = nameParts;
      middleName = '';
    } else if (nameParts.length > 2) {
      firstName = nameParts[0];
      lastName = nameParts[nameParts.length - 1];
      middleName = nameParts.slice(1, -1).join(' ');
    } else {
      firstName = fullName;
      middleName = '';
      lastName = '';
    }
  
    return { firstName, middleName, lastName };
  }


  const handleSaveRow = async (isOldData) => {
    const dataToUpdate = isOldData ? oldData : data;
    const setDataToUpdate = isOldData ? setOldData : setData;
    const editableData = isOldData ? editableOldRowData : editableRowData;
     

    const { SSN: newSSN } = editableData;
    let existingEmployee = null;
    let existingData = null;
    
    // If we're editing an existing record, use exact key match
    if (editableData.originalKey) {
        if (data?.Row?.[editableData.originalKey]) {
            existingEmployee = data.Row[editableData.originalKey];
            existingData = data;
        } else if (oldData?.Row?.[editableData.originalKey]) {
            existingEmployee = oldData.Row[editableData.originalKey];
            existingData = oldData;
        }
    } 
    // If adding new record, use SSN lookup
    else {
        // Check in data
        if (data?.Row) {
            const existingKey = Object.keys(data.Row).find(key => 
                data.Row[key].SSN?.FinalValue?.Value === newSSN
            );
            if (existingKey) {
                existingEmployee = data.Row[existingKey];
                existingData = data;
                editableData.originalKey = existingKey;
            }
        }
    
        // Check in oldData if not found
        if (!existingEmployee && oldData?.Row) {
            const existingKey = Object.keys(oldData.Row).find(key => 
                oldData.Row[key].SSN?.FinalValue?.Value === newSSN
            );
            if (existingKey) {
                existingEmployee = oldData.Row[existingKey];
                existingData = oldData;
                editableData.originalKey = existingKey;
            }
        }
    }

    let {
        FullName,
        FirstName,
        LastName,
        MiddleName,
        DateOfBirth,
        DateOfHire,
        DateOfReHire,
        DateOfTermination,
        Status,
        GrossCompensation,
        GrossCompensationNote,
        HoursWorked,
        HoursWorkedNote,
        DeferALS,
        ParticipantRoth,
        CompanyMatch,
        CompanyProfitSharing,
        AnnualLoanPayment,
        RollOver,
        SSN,
        originalKey: oldKey
    } = editableData;

    if (FullName) {
      const nameParts = splitFullName(FullName);
      FirstName = nameParts.firstName;
      MiddleName = nameParts.middleName || '';
      LastName = nameParts.lastName || '';
    }


    if (!FullName?.trim()) {
      alert("Please enter a name");
      return;
    } 
  

    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    FirstName = capitalizeFirstLetter(FirstName);
    MiddleName = capitalizeFirstLetter(MiddleName);
    LastName = capitalizeFirstLetter(LastName);

    const normalizeToDecimal = (value) => {
        if (!value) return '0.00';
        const cleanedValue = value.replace(/[$,]/g, ''); // Remove $ and ,
        const normalizedValue = parseFloat(cleanedValue).toFixed(2); // Convert to float and fix to 2 decimal places
        return normalizedValue;
    };

    GrossCompensation = GrossCompensation ? normalizeToDecimal(GrossCompensation) : GrossCompensation;
    HoursWorked = HoursWorked ? normalizeToDecimal(HoursWorked) : HoursWorked;
    DeferALS = DeferALS ? normalizeToDecimal(DeferALS) : DeferALS;
    ParticipantRoth = ParticipantRoth ? normalizeToDecimal(ParticipantRoth) : ParticipantRoth;
    CompanyMatch = CompanyMatch ? normalizeToDecimal(CompanyMatch) : CompanyMatch;
    CompanyProfitSharing = CompanyProfitSharing ? normalizeToDecimal(CompanyProfitSharing) : CompanyProfitSharing;
    AnnualLoanPayment = AnnualLoanPayment ? normalizeToDecimal(AnnualLoanPayment) : AnnualLoanPayment;
    RollOver = RollOver ? normalizeToDecimal(RollOver) : RollOver;
    

    let newKey = '';
    let isDuplicate = false;
    
    if (existingEmployee) {
        // If we found an existing employee, use their original key
        newKey = oldKey;
    } else {
        // Original logic for new employees
        if (oldKey) {
            isDuplicate = oldKey.endsWith('_duplicate');
        } else {
            // Generate a temporary key if oldKey is not available
            newKey = `${FirstName}_${LastName || 'Unknown'}_${DateOfBirth || 'Unknown'}`;
        }
    
        newKey = await generateOriginalKey(SSN, FirstName, LastName, DateOfBirth, customerNumber);
    
        if (isDuplicate) {
            newKey += '_duplicate';
        }
    }
    
    if (!dataToUpdate.Row) {
        dataToUpdate.Row = {};
    }

    const defaultFieldData = () => ({
        Values: [{ Value: '', Source: 'manual_input' }],
        FinalValue: { Value: '', Source: 'manual_input', Status: '', Message: '' }
    });

    const createOrUpdateField = (field, value) => {
      // Get existing field from the OLD data since that's where our match was found
      let existingField = existingData?.Row[oldKey]?.[field];
    
      if (field === 'PriorYearEmployee' && existingField?.FinalValue?.Source === 'PriorYear') {
        return existingField;
      }
    
      // If it's a Status field, always use the new value
      if (field === 'Status') {
        if (!existingField || !existingField.Values) {
          existingField = defaultFieldData();
        }
        
        let manualInputIndex = existingField.Values.findIndex(v => 
          v.Source === 'manual_input'
        );
    
        if (manualInputIndex === -1) {
          existingField.Values.push({ Value: value, Source: 'manual_input' });
        } else {
          existingField.Values[manualInputIndex].Value = value;
        }
    
        existingField.FinalValue = {
          Value: value,
          Source: 'manual_input',
          Status: 'updated',
          Message: 'Value updated'
        };
    
        return existingField;
      }
    
      if (existingField?.FinalValue?.Value === 'conflict') {
        return existingField;
      }
    
      if (!existingField || !existingField.Values) {
        existingField = defaultFieldData();
      }
    
      // Special handling for DateOfHire
      if (field === 'DateOfHire') {
        const priorYearValue = existingField.Values?.find(v => 
            v.Source?.toLowerCase() === 'prioryear'
        );
        
        // Add check for termination date and its source
        const terminationDate = existingData?.Row[oldKey]?.DateOfTermination?.FinalValue;
        const hasTerminationFromPriorYear = terminationDate?.Value && 
            terminationDate.Source?.toLowerCase() === 'prioryear' &&
            new Date(terminationDate.Value) < new Date(censusYearData.current_year_start_date);
    
        // Only mark as rehire if both conditions are met
        if (priorYearValue && hasTerminationFromPriorYear) {
            return {
                Values: [
                    priorYearValue,
                    { Value: value, Source: 'manual_input' }
                ],
                FinalValue: {
                    Value: 'rehire',
                    Source: 'manual_input',
                    Status: 'updated',
                    Message: 'Rehire detected'
                }
            };
        }
    }
    
      // Handle all other fields
      const originalValue = existingField?.FinalValue?.Value;
      const originalSource = existingField?.FinalValue?.Source;
    
      // Only proceed if values are different
      if (value !== originalValue) {
        if (value?.trim?.() !== '') {
          let manualInputIndex = existingField.Values.findIndex(v => 
              v.Source === 'manual_input'
          );
  
          if (manualInputIndex === -1) {
              existingField.Values.push({ Value: value, Source: 'manual_input' });
          } else {
              existingField.Values[manualInputIndex].Value = value;
          }
      }
      const terminationDate = existingData?.Row[oldKey]?.DateOfTermination?.FinalValue;
      const hasTerminationFromPriorYear = terminationDate?.Value && 
          terminationDate.Source?.toLowerCase() === 'prioryear' &&
          new Date(terminationDate.Value) < new Date(censusYearData.current_year_start_date);
          
    
            if (hasTerminationFromPriorYear && originalValue !== value && value?.trim?.() !== '') {
              // Only create a conflict if there was a meaningful original value
              if (originalValue && originalValue.trim() !== '' && originalSource.toLowerCase() == 'prioryear') {
                  existingField.FinalValue = {
                      Value: 'conflict',
                      Source: 'manual_input',
                      Status: 'Error',
                      Message: ``
                  };
              } else {
                  // If original value was empty/null, we can safely use the new value
                  existingField.FinalValue = {
                      Value: value,
                      Source: 'manual_input',
                      Status: 'updated',
                      Message: 'Value updated'
                  };
              }
          } else {
              // Original else case remains the same
              existingField.FinalValue = {
                  Value: value,
                  Source: 'manual_input',
                  Status: 'updated',
                  Message: 'Value updated'
              };
          }
        // If new value is empty/null, FinalValue remains unchanged
      }
    
      return existingField;
    };
  
  

    const fields = {
        FirstName, LastName, MiddleName, DateOfBirth, DateOfHire, DateOfReHire, DateOfTermination,
        Status, GrossCompensation, GrossCompensationNote, HoursWorked, HoursWorkedNote, DeferALS,
        ParticipantRoth, CompanyMatch, CompanyProfitSharing, AnnualLoanPayment, RollOver, SSN
    };

    const newEntry = {};
    for (const [field, value] of Object.entries(fields)) {
        newEntry[field] = createOrUpdateField(field, value || '');
    }

    // Copy all existing fields from the existing entry
    if (existingEmployee && oldKey) {
      Object.keys(existingEmployee).forEach(key => {
          if (!newEntry.hasOwnProperty(key) || 
              (key === 'PriorYearEmployee' && existingEmployee[key]?.FinalValue?.Source === 'PriorYear')) {
              newEntry[key] = existingEmployee[key];
          }
      });
    }

    // Ensure all fields are populated with default values if necessary
    const allFields = [
        'PriorYearEmployee', 'FirstName', 'LastName', 'MiddleName', 'OwnershipPercent', 'SSN',
        'DateOfBirth', 'DateOfHire', 'DateOfReHire', 'DateOfTermination', 'Status', 'GrossCompensation',
        'GrossCompensationNote', 'HoursWorked', 'HoursWorkedNote', 'DeferALS', 'ParticipantRoth', 
        'CompanyMatch', 'CompanyProfitSharing', 'AnnualLoanPayment', 'RollOver'
    ];
    allFields.forEach(field => {
      if (!newEntry[field]) {
          if (field === 'PriorYearEmployee' && oldKey && dataToUpdate.Row[oldKey]?.[field]?.FinalValue?.Source === 'PriorYear') {
              newEntry[field] = dataToUpdate.Row[oldKey][field];
          } else {
              newEntry[field] = defaultFieldData();
          }
      }
  });

    newEntry['Id'] = newKey;

    // Remove old entry
    if (oldKey) {
        delete dataToUpdate.Row[oldKey];
    }

    // Determine the correct data set based on DateOfTermination
    const endDateYear = new Date(DateOfTermination).getFullYear();
    const censusYear = parseInt(censusYearData.current_year);
    const targetData = (isNaN(endDateYear) || endDateYear >= censusYear) ? data : oldData;
    const setTargetData = (isNaN(endDateYear) || endDateYear >= censusYear) ? setData : setOldData;
    const removeFromData = (isNaN(endDateYear) || endDateYear >= censusYear) ? setOldData : setData;

    // Add new entry to the target data set
    if (!targetData?.Row) {
        targetData.Row = {};
    }
    targetData.Row[newKey] = newEntry;

    // Update state
    setTargetData({ ...targetData });
    removeFromData(prevData => {
        const newData = { ...prevData };
        delete newData.Row[newKey];
        return newData;
    });

    // Reset editing states
    isOldData ? setIsAddingOldNewRow(false) : setIsAddingNewRow(false);
    isOldData ? setEditableOldRowIndex(null) : setEditableRowIndex(null);

    setSaveRequest(true);
};

  const handleSaveHoursWorked = (employeeKey, hoursWorked, description) => {
    const updatedData = isOldDataSaving ? { ...oldData } : { ...data };
    const employee = updatedData.Row[employeeKey];
    
    if (!employee.HoursWorked) {
      employee.HoursWorked = {
        Values: [],
        FinalValue: {}
      };
    }
  
    if (!employee.HoursWorkedNote) {
      employee.HoursWorkedNote = {
        Values: [],
        FinalValue: {}
      };
    }
  
    const newHoursWorkedValue = { Value: hoursWorked, Source: 'manual_input' };
    const newHoursWorkedNoteValue = { Value: description, Source: 'manual_input' };
  
    employee.HoursWorked.Values.push(newHoursWorkedValue);
    employee.HoursWorked.FinalValue = newHoursWorkedValue;
  
    employee.HoursWorkedNote.Values.push(newHoursWorkedNoteValue);
    employee.HoursWorkedNote.FinalValue = newHoursWorkedNoteValue;
  
    isOldDataSaving ? setOldData(updatedData) : setData(updatedData);
    setSaveRequest(true);
  };
  


  const handleInputChange = (field, value, isOldData) => {
    const setEditableData = isOldData ? setEditableOldRowData : setEditableRowData;
  
    if (['DateOfHire', 'DateOfBirth', 'DateOfTermination', 'DateOfReHire'].includes(field)) {
      if (value) {
        const [month, day, year] = value.split('/');
        
        if (month && day && year) {
          const formattedDate = `${month}-${day}-${year}`;
          setEditableData(prevData => ({ ...prevData, [field]: formattedDate }));
        } else {
          setEditableData(prevData => ({ ...prevData, [field]: value }));
        }
      } else {
        setEditableData(prevData => ({ ...prevData, [field]: '' }));
      }
    } else {
      setEditableData(prevData => ({ ...prevData, [field]: value }));
    }
  };
  
  
  const handleConflictChange = (selectedConflict) => {
    const { employeeKey, field, Value, Source, isRehireConflict } = selectedConflict;
    
    // Create a copy of both data and oldData
    const newData = JSON.parse(JSON.stringify(data));
    const newOldData = JSON.parse(JSON.stringify(oldData));
    
    // Function to update the employee record
    const updateEmployeeRecord = (dataObj, key) => {
      if (dataObj.Row[key]) {
        if (field === 'FullName') {
          const { firstName, lastName } = selectedConflict;
          if (dataObj.Row[key].FirstName) {
            dataObj.Row[key].FirstName.FinalValue = { 
              Value: firstName.Value, 
              Source: firstName.Source, 
              Status: "Valid", 
              Message: "First Name conflict resolved." 
            };
          }
          if (dataObj.Row[key].LastName) {
            dataObj.Row[key].LastName.FinalValue = { 
              Value: lastName.Value, 
              Source: lastName.Source, 
              Status: "Valid", 
              Message: "Last Name conflict resolved." 
            };
          }
        } else if (dataObj.Row[key][field]) {
          dataObj.Row[key][field].FinalValue = { 
            Value, 
            Source, 
            Status: "Valid", 
            Message: `${field} conflict resolved.` 
          };

          // If this is a rehire conflict, also update the DateOfReHire field
          if (isRehireConflict && dataObj.Row[key].DateOfReHire) {
            const existingTerminationDate = dataObj.Row[key].DateOfTermination?.FinalValue?.Value;
            const newDate = new Date(Value);
            const terminationDate = existingTerminationDate ? new Date(existingTerminationDate) : null;

            dataObj.Row[key].DateOfReHire.FinalValue = {
              Value,
              Source,
              Status: "Valid",
              Message: "Date of rehire updated."
            };

            // Only clear termination date if the new hire/rehire date is after the termination date
            if (terminationDate && newDate > terminationDate) {
              dataObj.Row[key].DateOfTermination.FinalValue = {
                Value: "",
                Source: "",
                Status: "",
                Message: ""
              };
              
              dataObj.Row[key].Status.FinalValue = {
                Value: "Active",
                Source,
                Status: "Valid",
                Message: "Date of rehire updated."
              };
            } else if (terminationDate) {
              // If hire date is before termination, keep as terminated
              dataObj.Row[key].Status.FinalValue = {
                Value: "Terminated",
                Source,
                Status: "Valid",
                Message: "Employee terminated after hire date."
              };

              // Set GrossCompensation and HoursWorked status to Valid
              if (dataObj.Row[key].GrossCompensation?.FinalValue) {
                dataObj.Row[key].GrossCompensation.FinalValue.Status = "Valid";
              }
              if (dataObj.Row[key].HoursWorked?.FinalValue) {
                dataObj.Row[key].HoursWorked.FinalValue.Status = "Valid";
              }
            }
          }
        }
      }

      setEditableRowIndex(null);
      setIsAddingNewRow(false);
      setEditableRowData(initialEditableRowData);
    };
  
    // Get the key without "_duplicate" suffix
    const mainKey = employeeKey.replace('_duplicate', '');
  
    // Update both the duplicate and main records in both data and oldData
    [newData, newOldData].forEach(dataObj => {
      updateEmployeeRecord(dataObj, employeeKey);
      updateEmployeeRecord(dataObj, mainKey);
    });
  
    // Update the state
    setData(newData);
    setOldData(newOldData);
  
    // Trigger save operation
    setSaveRequest(true);
  };
  
  const handleAlertClick = (filter, isOldData) => {
    handlePageChange(1, isOldData);
    if (isOldData) {
      setPreviousYearAlertFilters(prevFilters =>
        prevFilters.includes(filter)
          ? prevFilters.filter(f => f !== filter)
          : [...prevFilters, filter]
      );
    } else {
      setCurrentYearAlertFilters(prevFilters =>
        prevFilters.includes(filter)
          ? prevFilters.filter(f => f !== filter)
          : [...prevFilters, filter]
      );
    }
  };
  

  const filteredData = filterData(data, currentYearSearchTerm, currentYearAlertFilters, censusYearData);
  const oldFilteredData = filterData(oldData, previousYearSearchTerm, previousYearAlertFilters, censusYearData);
  

  function formatDate(dateString) {
    if (!dateString) return '';
  
    const [year, month, day] = dateString.split('-');
    const date = new Date(Date.UTC(year, month - 1, day));
  
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      timeZone: 'UTC', // Specify UTC time zone
    }).toUpperCase();
  }
  
  const priorCensusTitle = `PAST YEAR CENSUS CHANGES (${formatDate(censusYearData?.prior_year_start_date)} - ${formatDate(censusYearData?.prior_year_end_date)})`;

  

  return (
    <div className={`results-content-container ${isSidebarVisible ? 'with-sidebar' : 'no-sidebar'}`}>
      {isSidebarVisible && <Sidebar sources={sources} setCurrentView={setCurrentView} toggleSidebar={toggleSidebar} />}
      {/* <div className="toggle-button-wrapper">
        <img
          src={sideBarToggleButton}
          alt="Toggle Sidebar"
          className={`toggle-sidebar-button ${!isSidebarVisible ? 'rotated' : ''}`}
          onClick={toggleSidebar}
        />
      </div> */}
      <div className="results-content-wrapper">

      {/* <div className="onboarding-overlay">
        <div className="spotlight-hole" style={{ 
          top: document.querySelector('.results-table')?.offsetTop || 0,
          height: document.querySelector('.results-table')?.offsetHeight || 0
        }}></div>
      </div> */}
        <div className="content-wrapper">
          <MainContentHeader
            start_date={censusYearData?.current_year_start_date ?? ''}
            end_date={censusYearData?.current_year_end_date ?? ''}
            subtitle="Submit Employee and Owner Information"
            description="The table displays employees and owners that were active at the beginning of the plan year. To complete your census, add new employees, verify and update statuses, and provide payroll details. Resolve any errors and review warnings to proceed to the next step."
            currentView={currentView}
          />

        {/* <button className="add-employee-button" onClick={() => handleAddRow(false)}>
          <img src={editPencilButton} alt="Add Employee" className="icon" />
          ADD EMPLOYEE
        </button> */}
        <div className="buttons">
          <button className="button-add-manually" onClick={() => handleAddRow(false)}>
            <img src={addManuallyIcon} alt="Add Manually" />
            ADD MANUALLY
          </button>
          <button className="button-upload-file" onClick={() => setCurrentView('upload')}>
            <img src={uploadFileIcon} alt="Upload File" />
            UPLOAD FILE
          </button>
        </div>
          {processingStatus.isProcessing && (
            <div className="processing-warning" onClick={() => checkProcessingStatusAndShowModal()}>
              <span className="processing-warning-icon">
                <i className="fas fa-info-circle"></i>
              </span>
              Editing the table is unavailable while documents are processing. Click to learn more.
            </div>
          )}
          <ResultsTableSection
            title="CURRENT YEAR CENSUS"
            data={filteredData}
            currentPage={currentPage}
            rowsPerPage={8}
            isAddingNewRow={isAddingNewRow}
            editableRowIndex={editableRowIndex}
            editableRowData={editableRowData}
            handlePageChange={(pageNumber) => handlePageChange(pageNumber, false)}
            handleEditRow={(key) => handleEditRow(key, false)}
            handleSaveRow={() => handleSaveRow(false)}
            handleInputChange={(field, value) => handleInputChange(field, value, false)}
            handleResolveClick={(values, employeeKey, field) => handleResolveClick(values, employeeKey, field, false)}
            handleBulkResolveClick={(field) => handleBulkResolveClick(field, false)}
            handleZeroHourClick={(employeeKey, isOldData, hoursFinalValue, grossCompensation, hoursWorkedNoteFinalValue, isOwner) => handleZeroHourClick(employeeKey, false, hoursFinalValue, grossCompensation, hoursWorkedNoteFinalValue, isOwner)}
            initialEditableRowData={initialEditableRowData}
            setIsAddingNewRow={setIsAddingNewRow}
            setEditableRowIndex={setEditableRowIndex}
            setEditableRowData={setEditableRowData}
            setCurrentView={setCurrentView}
            isOldData={false}
            showTable={showCurrentYearTable}
            setShowTable={setShowCurrentYearTable}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            searchTerm={currentYearSearchTerm}
            setSearchTerm={(term) => HandleSetCurrentYearSearchTerm(term)}
            handleAddRow={() => handleAddRow(false)}
            alertFilters={currentYearAlertFilters}
            handleAlertClick={(filter) => handleAlertClick(filter, false)}
            setData = {setData}
            setOldData = {setOldData}
            handleDeleteRowClick={handleDeleteRowClick}
            alerts={currentYearAlerts}
          />
          {(Object.keys(oldFilteredData.Row || {}).length > 0 || (previousYearAlertFilters && previousYearAlertFilters.length > 0) || previousYearSearchTerm) && (
            <>
              <div className="divider"></div>
              <ResultsTableSection
                title={priorCensusTitle}
                data={oldFilteredData}
                currentPage={oldCurrentPage}
                rowsPerPage={8}
                isAddingNewRow={isAddingOldNewRow}
                editableRowIndex={editableOldRowIndex}
                editableRowData={editableOldRowData}
                handlePageChange={(pageNumber) => handlePageChange(pageNumber, true)}
                handleEditRow={(key) => handleEditRow(key, true)}
                handleSaveRow={() => handleSaveRow(true)}
                handleInputChange={(field, value) => handleInputChange(field, value, true)}
                handleResolveClick={(values, employeeKey, field) => handleResolveClick(values, employeeKey, field, true)}
                handleBulkResolveClick={(field) => handleBulkResolveClick(field, true)}
                handleZeroHourClick={(employeeKey, isOldData, hoursFinalValue, grossCompensation, hoursWorkedNoteFinalValue, isOwner) => handleZeroHourClick(employeeKey, true, hoursFinalValue, grossCompensation, hoursWorkedNoteFinalValue, isOwner)}
                initialEditableRowData={initialEditableRowData}
                setIsAddingNewRow={setIsAddingOldNewRow}
                setEditableRowIndex={setEditableOldRowIndex}
                setEditableRowData={setEditableOldRowData}
                setCurrentView={setCurrentView}
                isOldData={true}
                showTable={showPreviousYearTable}
                setShowTable={setShowPreviousYearTable}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                searchTerm={previousYearSearchTerm}
                setSearchTerm={(term) => HandleSetPreviousYearSearchTerm(term)}
                handleAddRow={() => handleAddRow(true)}
                alertFilters={previousYearAlertFilters}
                handleAlertClick={(filter) => handleAlertClick(filter, true)}
                setData = {setData}
                setOldData = {setOldData}
                handleDeleteRowClick={handleDeleteRowClick}
                alerts={previousYearAlerts}
              />
            </>
          )}
          <div className="save-button-container">
            <button className="delete-button" onClick={handleDeleteProjectClick}>
              <img src={whiteTrashIcon} alt="Delete" className="icon" />
              Start Over
            </button>
            <button className="save-button" onClick={SubmitFinalData}>
              <img src={saveIcon} alt="Save" className="icon" />
              Save and Continue
            </button>
          </div>
          <ConflictModal
            show={showConflictModal}
            onClose={() => setShowConflictModal(false)}
            conflictValues={conflictValues}
            reportEndDates={reportEndDates}
            onApplyChange={handleConflictChange}
            isRehireConflict={isRehireConflict}
          />
          <BulkConflictModal
            show={showBulkConflictModal}
            onClose={() => setShowBulkConflictModal(false)}
            sources={bulkConflictSources}
            onApplyChange={handleBulkConflictChange}
            bulkConflictField={bulkConflictField}
          />

          <HoursWorkedModal
            show={showHoursModal}
            onClose={() => setShowHoursModal(false)}
            onSave={handleSaveHoursWorked}
            employeeFullName={selectedEmployeeFullName}
            hoursFinalValue={hoursFinalValue}
            hoursWorkedNoteFinalValue={hoursWorkedNoteFinalValue}
            hoursIsOwner={hoursIsOwner}
            hoursGrossCompensation={hoursGrossCompensation}
            isOldData={isOldDataSaving}
          />

          <ProcessingModal
            show={showProcessingModal}
            onClose={() => setShowProcessingModal(false)}
            processingStatus={processingStatus}
          />
          <DeleteConfirmationModal
            show={showDeleteModal}
            onClose={handleCloseDeleteModal}
            handleConfirmDeleteRow={handleConfirmDeleteRow}
            handleConfirmDeleteProject={handleConfirmDeleteProject}
            deleteMessage={deleteMessage}
            isDeletingAll={isDeletingAll}
          />
          <ValidationErrorModal
            show={showValidationModal}
            onClose={() => setShowValidationModal(false)}
            errors={validationErrors}
          />
        </div>
      </div>
    </div>
  );
  
}

export default ResultsContent;
