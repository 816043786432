import { useState, useEffect } from 'react';
import { getResults } from '../../api';


const initialEditableRowData = {
  FullName: '',
  FirstName: '',
  LastName: '',
  MiddleName: '',
  SSN: '',
  DateOfBirth: '',
  DateOfHire: '',
  DateOfReHire: '',
  DateOfTermination: '',
  Status: 'Active',
  GrossCompensation: '',
  HoursWorked: '',
  DeferALS: '',
  ParticipantRoth: '',
  CompanyMatch: '',
  CompanyProfitSharing: '',
  AnnualLoanPayment: '',
  RollOver: ''
};



const splitDataByEndDate = (data, censusYear) => {
  console.log(data);
  const mainTableData = { Row: {} };
  const secondaryTableData = { Row: {} };

  const priorStart = new Date(censusYear.prior_year_start_date + 'T00:00:00');
  const priorEnd = new Date(censusYear.prior_year_end_date + 'T00:00:00');
  const currentStart = new Date(censusYear.current_year_start_date + 'T00:00:00');
  const currentEnd = new Date(censusYear.current_year_end_date + 'T00:00:00');

  Object.entries(data.Row).forEach(([key, employee]) => {
    const terminationDate = employee.DateOfTermination?.FinalValue?.Value;
    
    const isNewHire = employee.PriorYearEmployee?.FinalValue?.Message === "NewHire";
    const isDuplicate = key.endsWith('_duplicate');

    if (isNewHire || isDuplicate) {
      secondaryTableData.Row[key] = employee;
      return;
    }

    if (terminationDate) {
      // Convert MM-DD-YYYY to YYYY-MM-DD
      const [month, day, year] = terminationDate.split('-');
      const formattedTermDate = `${year}-${month}-${day}T00:00:00`;
      const termDate = new Date(formattedTermDate);
      
      if (termDate >= priorStart && termDate <= priorEnd) {
        secondaryTableData.Row[key] = employee;
        return;
      }
      
      if (termDate >= currentStart && termDate <= currentEnd) {
        mainTableData.Row[key] = employee;
        return;
      }
    }

    mainTableData.Row[key] = employee;
  });

  return { mainTableData, secondaryTableData };
};

const useResultsData = (currentProject, useLocalResults, currentView, setIsLoading, iFinanceId, censusYearData, customerNumber) => {
  const [data, setData] = useState([]);
  const [oldData, setOldData] = useState([]);
  const [reportStartDates, setReportStartDates] = useState([]);
  const [reportEndDates, setReportEndDates] = useState([]);
  const [sources, setSources] = useState([]);
  const [currentYearAlerts, setCurrentYearAlerts] = useState({
    errors: 0,
    warnings: 0,
    invalids: 0
  });

  const [previousYearAlerts, setPreviousYearAlerts] = useState({
    errors: 0,
    warnings: 0,
    invalids: 0
  });

  const countAlerts = (tableData) => {
    let alerts = {
      errors: 0,
      warnings: 0,
      invalids: 0
    };
  
    Object.values(tableData.Row).forEach(row => {
      const statuses = new Set(
        Object.values(row)
          .map(field => field?.FinalValue?.Status)
          .filter(Boolean)
      );
  
      if (statuses.has("Error")) alerts.errors++;
      if (statuses.has("Warning")) alerts.warnings++;
      if (statuses.has("Invalid")) alerts.invalids++;
    });
  
    return alerts;
  };
  

  const fetchResults = async () => {
    setIsLoading(true);
    if (!currentProject) {
      setIsLoading(false);
      return;
    }
    try {
      const response = useLocalResults
        ? await fetch('/results.json').then(res => res.json())
        : await getResults(
            currentProject,
            iFinanceId,
            customerNumber,
            censusYearData.current_year,
            censusYearData.current_year_start_date,
            censusYearData.current_year_end_date,
            censusYearData.prior_year_start_date,
            censusYearData.prior_year_end_date,
          );
      if (response) {
        const results = response;
        const { mainTableData, secondaryTableData } = splitDataByEndDate(results, censusYearData);
        
        setData(mainTableData);
        setOldData(secondaryTableData);
  
        const currentAlerts = countAlerts(mainTableData);
        const previousAlerts = countAlerts(secondaryTableData);
        
        setCurrentYearAlerts(currentAlerts);
        setPreviousYearAlerts(previousAlerts);
  
        setReportEndDates(results.ReportPeriodEndDate || []);
        setReportStartDates(results.ReportPeriodStartDate || []);
        
        const startDateSources = results.ReportPeriodStartDate?.map(item => item.Source).filter(Boolean) || [];
        const endDateSources = results.ReportPeriodEndDate?.map(item => item.Source).filter(Boolean) || [];
        const uniqueSources = Array.from(new Set([...startDateSources, ...endDateSources]));
        setSources(uniqueSources);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    oldData,
    reportStartDates,
    reportEndDates,
    initialEditableRowData,
    setData,
    setOldData,
    sources,
    censusYearData,
    fetchResults,
    currentYearAlerts,
    previousYearAlerts
  };
};

export default useResultsData;
