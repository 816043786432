import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { isTokenValid, redirectToIdentityServer, identityServerUrl } from './TokenUtils';
import { getConfig } from './TokenConfig';

function SigninCallback({ setUser, setIsLoading, setIFinanceId }) {
 const navigate = useNavigate();
 const location = useLocation();
 const config = getConfig();
 const isDevEnvironment = config.hostname.includes('localhost') || 
                         config.hostname.includes('censusupload-dev.guidantfinancial.com');

 useEffect(() => {
   const processDevSignin = async () => {
     try {
       const params = new URLSearchParams(location.search);
       const code = params.get('code');
       
       if (!code) {
         throw new Error('No authorization code found in callback');
       }

       const codeVerifier = sessionStorage.getItem('code_verifier');
       if (!codeVerifier) {
         throw new Error('No code verifier found');
       }

       const requestBody = new URLSearchParams({
         grant_type: 'authorization_code',
         client_id: config.clientId,
         code_verifier: codeVerifier,
         code: code,
         redirect_uri: config.redirectUri
       });

       const response = await fetch(`${identityServerUrl}/connect/token`, {
         method: 'POST',
         headers: {
           'Content-Type': 'application/x-www-form-urlencoded'
         },
         body: requestBody
       });

       if (!response.ok) {
         const errorData = await response.text();
         throw new Error(`Token exchange failed: ${errorData}`);
       }

       const tokens = await response.json();
       const idToken = tokens.id_token;
       if (!idToken) {
         throw new Error('No id_token received in token response');
       }
       
       const decodedIdToken = jwtDecode(idToken);
       if (isTokenValid(decodedIdToken)) {
         setUser({ idToken, decodedIdToken });
         localStorage.setItem('id_token', idToken);
         localStorage.setItem('decoded_token', JSON.stringify(decodedIdToken));
         
         const storedId = localStorage.getItem('iFinanceId');
         if (storedId) {
           setIFinanceId(storedId);
         }

         navigate('/');
         return;
       } else {
         throw new Error('ID token validation failed');
       }
     } catch (error) {
       console.error('Error processing signin:', error);
       redirectToIdentityServer();
     }
   };

   const processProdSignin = async () => {
     try {
       const params = new URLSearchParams(location.hash.slice(1));
       const idToken = params.get('id_token');
       if (idToken) {
         const decodedIdToken = jwtDecode(idToken);
         if (isTokenValid(decodedIdToken)) {
           const storedNonce = sessionStorage.getItem('auth_nonce');
           if (!storedNonce || decodedIdToken.nonce !== storedNonce) {
             throw new Error('Nonce validation failed');
           }
           sessionStorage.removeItem('auth_nonce');
           setUser({ idToken, decodedIdToken });
           localStorage.setItem('id_token', idToken);
           localStorage.setItem('decoded_token', JSON.stringify(decodedIdToken));
           window.history.replaceState({}, document.title, window.location.pathname);

           const storedId = localStorage.getItem('iFinanceId');
           if (storedId) {
             setIFinanceId(storedId);
           }

           navigate('/');
           return;
         }
       }
       throw new Error('Invalid or missing ID token');
     } catch (error) {
       console.error('Error processing ID token:', error);
       redirectToIdentityServer();
     }
   };

   processProdSignin();
  //  if (isDevEnvironment) {
  //    processDevSignin();
  //  } else {
  //    processProdSignin();
  //  }
 }, [location, navigate, setUser, setIFinanceId, config]);

 return null;
}

export default SigninCallback;