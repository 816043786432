import React, {useEffect} from 'react';
import './HelpModal.css';
import restartIcon from '../../assets/restart-icon.svg';
import externalGuideIcon from '../../assets/external-guide-icon.svg';

function HelpModal({ onClose, showOnboardingGuide }) {
  
  useEffect(() => {
    const timer = setTimeout(() => {
        onClose();
    }, 5000);

    return () => clearTimeout(timer);
}, []); 
  
  const handleRestartGuide = () => {
      showOnboardingGuide();
      onClose();
    };

  const handleViewExternalGuide = () => {
    window.open("https://scribehow.com/embed-preview/Census_Walkthrough__rOP02YP7T6e2znesSCaQ1Q", "_blank");
    onClose();
  };

  return (
    <div className="help-modal">
      <div className="help-modal-content">
        <button className="help-modal-close" onClick={onClose}>×</button>
        <div className="help-modal-body">
          <h3 className="help-modal-title">
            Need a hand? Restart the guide or check an external resource below.
          </h3>
        </div>
        <div className="help-modal-divider"></div>
        <div className="help-modal-footer">
          <button 
            className="help-modal-button restart-button"
            onClick={handleRestartGuide}
          >
            <img src={restartIcon} alt="" />
            RESTART GUIDE
          </button>
          <button 
            className="help-modal-button external-button"
            onClick={handleViewExternalGuide}
          >
            VIEW EXTERNAL GUIDE
            <img src={externalGuideIcon} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default HelpModal;